import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3a2725aad0c64acc9276f9c28c5936b1@sentry-rnd-prod.bbrp.co/67",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});